import type { ImageOptions } from "@nuxt/image";

export interface GalleryVideoItem {
  collection: "videos";
  item: VideoItem;
}

export interface GalleryImageItem {
  collection: "images";
  item: ImageItem;
}

export type GalleryItem = GalleryVideoItem | GalleryImageItem;

export const usePostGallery = () => {
  /**
   * Get all images from post
   * @param {MaybeRef<PostItem>} post the post to get images from
   * @returns {GalleryItem[]} the gallery items
   */
  const getAllPostMedia = (post: MaybeRef<PostItem>): GalleryItem[] => {
    post = unref(post);

    const items: GalleryItem[] = [];

    const videos =
      post?.videos?.map((item) => item.videos_id).filter((item) => !!item) ||
      [];

    videos.forEach((item) => {
      items.unshift({ collection: "videos", item });
    });

    const images =
      post?.images
        ?.sort((a, b) => (b.sort || 0) - (a.sort || 0))
        ?.map((item) => item.images_id)
        .filter((item) => !!item) || [];

    images.forEach((item) => {
      items.unshift({ collection: "images", item });
    });

    const contentMedia =
      post?.content
        ?.filter(
          (item) => item.collection === "images" || item.collection === "videos"
        )
        ?.reverse()
        ?.filter((item) => !!item.item)
        ?.map((item) => ({ collection: item.collection, item: item.item! })) ||
      [];

    contentMedia.forEach((item) => {
      if (
        !images.find((image) => image.id === item.item.id) &&
        !videos.find((video) => video.id === item.item.id)
      ) {
        items.unshift(item);
      }
    });

    const cover = post?.cover;

    if (cover && !images.find((item) => item.id === cover.id)) {
      items.unshift({ collection: "images", item: cover });
    }

    return items;
  };

  const route = useRoute();
  const router = useRouter();

  const currentItemId = useRouteQuery<string | undefined>("image", undefined, {
    route,
    router,
  });

  const lightboxState = reactive({
    isOpen: false,
    currentItem: null as GalleryItem | null,
  });

  const openLightbox = (item?: GalleryItem) => {
    if (item) {
      lightboxState.currentItem = item;
      currentItemId.value = item.item.id;
    }
    lightboxState.isOpen = true;
  };

  const closeLightbox = () => {
    lightboxState.isOpen = false;
    currentItemId.value = undefined;
  };

  const img = useImage();

  const getDirectusFile = (
    file: MaybeRef<FileItem>,
    options?: MaybeRef<ImageOptions>
  ) => {
    const source = unref(file).id;

    if (source) {
      return img.getImage(source, { provider: "directus", ...unref(options) });
    } else {
      return null;
    }
  };

  const getFileUrl = (file: MaybeRef<FileItem>) => {
    return getDirectusFile(file)?.url;
  };

  const getDirectusImage = (
    image: MaybeRef<ImageItem | VideoItem>,
    options?: MaybeRef<ImageOptions>
  ) => {
    const file = unref(image).file;

    if (file) {
      return getDirectusFile(file, options);
    } else {
      return null;
    }
  };

  const getImageUrl = (image: MaybeRef<ImageItem | VideoItem>) => {
    return getDirectusImage(image)?.url;
  };

  return {
    getAllPostMedia,
    currentItemId,
    lightboxState,
    openLightbox,
    closeLightbox,

    getDirectusFile,
    getFileUrl,

    getDirectusImage,
    getImageUrl,
  };
};
